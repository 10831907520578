import { graphql } from "gatsby"
import * as React from "react"
import styled from "styled-components"
import content from "../content.json"
import BlogPostDisplay from "../components/BlogPostDisplay"
import Layout from "../components/layout/Layout"
import Seo from "../components/Seo"

const Section = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 64px 16px;
  margin: 64px 32px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
    gap: 32px 16px;
    margin: 32px 16px;
  }

  @media (max-width: 575px) {
    grid-template-columns: 1fr;
  }
`

export default function BlogPage({ data }) {
  return (
    <Layout>
      <Seo {...content.pages.blog.seo} />
      <Section>
        {data.allMdx.nodes.map((post, i) => (
          <BlogPostDisplay
            {...post.frontmatter}
            key={i}
          />
        ))}
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query blog {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/blog/" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          cover {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                pngOptions: { quality: 80 }
                webpOptions: { quality: 70 }
                width: 700
              )
            }
          }
          author
          date
          description
          slug
          title
        }
      }
    }
  }
`
