import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { colors } from "../colors"

const Box = styled.div`
  h3 {
    text-transform: capitalize;
    color: ${colors.text};
  }

  .gatsby-image-wrapper {
    min-height: 180px;
  }
`

const ReadMore = styled(Link)`
  background: ${colors.button};
  padding: 8px 24px;
  border-radius: 16px;
  color: ${colors.text};
  font-weight: 500;
  box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.16);
  margin-top: 16px;
  display: inline-block;

  &:hover,
  &:focus {
    outline: none;
    box-shadow: 1px 3px 6px 0px rgba(0, 0, 0, 0.26);
  }
`

export default function PostDisplay({
  title,
  description,
  date,
  author,
  cover,
  slug,
}) {
  return (
    <Box>
      <GatsbyImage image={getImage(cover)} alt={title} />
      <Link to={`/blog/${slug}`}>
        <h3>{title}</h3>
      </Link>
      <p>
        {author ? author : "Marialy Guédez"} &bull; {date}{" "}
      </p>
      <p>{description}</p>
      <ReadMore to={`/blog/${slug}`}>READ MORE</ReadMore>
    </Box>
  )
}
